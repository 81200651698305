<template>
    <page class="home-page" title="首页" :hasBack="false" textAlign="center">
        <nav-page :roleType="2">
            <van-swipe :autoplay="3000" lazy-render>
                <van-swipe-item v-for="image in bannerList" :key="image">
                    <van-image :src="image.img" fit="cover" class="swiper-img" />
                </van-swipe-item>
            </van-swipe>
            <van-notice-bar class="notice" left-icon="volume-o" :text="`${hourName}，${userName}老师~`">
                <template v-slot:left-icon>
                    <img class="left-icon" src="@/assets/images/home/voice.png" alt="" />
                </template>
            </van-notice-bar>
            <div class="menu-list">
                <div v-for="item in menuList" class="menu-item" @click="menuClick(item.link)" :key="item.name">
                    <img :src="item.icon" alt="" />
                    <div class="title">{{ item.name }}</div>
                </div>
            </div>
            <common-block class="school-notice" title="我的代办">
                <template v-slot:right-icon>
                    <van-icon name="arrow" @click="readApproveMore" />
                </template>
                <template v-if="!agentListHasLoad || agentList.length">
                    <div class="agent-item" v-for="item in agentList" :key="item.id" @click="readNoticeDetail(item.id)">
                        <div class="header">
                            <div class="name">{{ item.title }}</div>
                            <div class="time">{{ item.datetime }}</div>
                        </div>
                        <div class="content">
                            <div class="text one-line-ellipsis">{{ item.content }}</div>
                        </div>
                    </div>
                    <read-more @read-more="readApproveMore" />
                </template>
                <div class="empty-wrapper" v-else>
                    <empty />
                </div>
            </common-block>
            <common-block class="student-wrapper" :border="false" title="学生实况">
                <template v-slot:right-icon>
                    <div class="reload" v-if="lastReloadTime">
                        <span class="time">{{ formatTime() }}</span>
                        <img @click="fetchClassLog('isFresh')" src="@/assets/images/teacherHome/reload.png" alt="" />
                    </div>
                </template>
                <template v-if="!classListHasLoad || classList.length">
                    <van-tabs
                        v-model:active="currentClass"
                        title-active-color="#07C160"
                        title-inactive-color="#666666"
                        color="#07C160"
                    >
                        <van-tab v-for="item in classList" :key="item.classId" :title="item.className" :name="item.classId">
                            <div class="total-wrapper">
                                <div class="am-item total-item">
                                    <div class="label">上午</div>
                                    <div class="detail">
                                        <div class="item">
                                            <div class="des">总人数</div>
                                            <div class="count">{{ item.amMsg.studentTotal }}</div>
                                        </div>
                                        <div class="item">
                                            <div class="des">已到人数</div>
                                            <div class="count">{{ item.amMsg.arriveTotal }}</div>
                                        </div>
                                        <div class="item">
                                            <div class="des">未到人数</div>
                                            <div class="count">{{ item.amMsg.noArriveTotal }}</div>
                                        </div>
                                        <div class="item">
                                            <div class="des">请假人数</div>
                                            <div class="count">{{ item.amMsg.leaveTotal }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pm-item total-item">
                                    <div class="label">下午</div>
                                    <div class="detail">
                                        <div class="item">
                                            <div class="des">总人数</div>
                                            <div class="count">{{ item.pmMsg.studentTotal }}</div>
                                        </div>
                                        <div class="item">
                                            <div class="des">已到人数</div>
                                            <div class="count">{{ item.pmMsg.arriveTotal }}</div>
                                        </div>
                                        <div class="item">
                                            <div class="des">未到人数</div>
                                            <div class="count">{{ item.pmMsg.noArriveTotal }}</div>
                                        </div>
                                        <div class="item">
                                            <div class="des">请假人数</div>
                                            <div class="count">{{ item.pmMsg.leaveTotal }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </van-tab>
                    </van-tabs>
                </template>
                <div class="empty-wrapper" v-else>
                    <empty />
                </div>
            </common-block>
        </nav-page>
    </page>
</template>
<script>
import Page from '@/components/page/Page.vue';
import CommonBlock from '@/components/commonBlock/CommonBlock.vue';
import NoticeItem from '@/components/noticeItem/NoticeItem.vue';
import { reactive, ref } from 'vue';
import { Swipe, SwipeItem, NoticeBar, Icon, Tab, Tabs, Image as VanImage, Toast } from 'vant';
import ReadMore from '@/components/readMore/ReadMore';
import Empty from '@/components/empty/Empty';
import CommonStep from '@/components/commonStep/CommonStep';
import { useRouter } from 'vue-router';
import NavPage from '@/components/navPage/NavPage';
import commonRequest from '@/services';
import dayjs from 'dayjs';
import {getStorage} from '@/utils/storage';

export default {
    name: 'teacherHome',
    components: {
        [Page.name]: Page,
        [CommonBlock.name]: CommonBlock,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [NoticeBar.name]: NoticeBar,
        [NoticeItem.name]: NoticeItem,
        [ReadMore.name]: ReadMore,
        [Empty.name]: Empty,
        [CommonStep.name]: CommonStep,
        [NavPage.name]: NavPage,
        [Icon.name]: Icon,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [VanImage.name]: VanImage,
    },
    setup() {
        const userName = getStorage('userName');
        const now = new Date();
        const hour = now.getHours();
        let hourName = '';
        if (hour < 12){
            hourName  = '早上好'
        } else if (hour < 17) {
            hourName = '下午好'
        } else {
            hourName  = '晚上好'
        }
        const router = useRouter();
        const readApproveMore = () => {
            router.push({
                path: '/approveList',
            });
        };
        const readNoticeDetail = (id) => {
            router.push({
                path: '/approveDetail',
                query: { id },
            });
        };
        const bannerList = ref([]);
        commonRequest({ action: 11001 }).then((res) => {
            if (res.data && res.data.rows) {
                bannerList.value = res.data.rows;
            }
        });
        const menuList = reactive([
            {
                name: '请假审批',
                link: '/approveList',
                icon: require('@/assets/images/teacherHome/approve.png'),
            },
            {
                name: '考勤点名',
                link: '/studentAttendance',
                icon: require('@/assets/images/teacherHome/user_call.png'),
            },
            {
                name: '代请假',
                link: '/teacherAskLeave',
                icon: require('@/assets/images/teacherHome/evaluation.png'),
            },
            {
                name: '人脸采集',
                link: '/classFace',
                icon: require('@/assets/images/teacherHome/face.png'),
            },
        ]);
        const agentList = ref([]);
        const agentListHasLoad = ref(false);

        commonRequest({ action: 12001, pageNum: 1, pageSize: 2 }).then((res) => {
            agentListHasLoad.value = true;
            if (res.rows) {
                agentList.value = res.rows;
            }
        });
        const classList = ref([
            // {
            //     name: '高一班',
            //     id: 1,
            //     type: 1,
            //     amMsg: { total: 100, arrive: 90, notArrive: 10, askLeave: 10 },
            //     pmMsg: { total: 100, arrive: 90, notArrive: 10, askLeave: 10 },
            // },
            // {
            //     name: '高三班',
            //     id: 2,
            //     type: 2,
            //     amMsg: { total: 100, arrive: 90, notArrive: 10, askLeave: 10 },
            //     pmMsg: { total: 100, arrive: 90, notArrive: 10, askLeave: 10 },
            // },
        ]);
        const classListHasLoad = ref(true);
        const currentClass = ref(1);
        const lastReloadTime = ref(new Date());
        const fetchClassLog = (isFresh) => {
            commonRequest({ action: 12004}).then((res) => {
                if (res.data) {
                    classList.value = res.data.map(item => {
                        const amMsg = item.studentSceneDatasResponses.find(chi => chi.type === 1);
                        const pmMsg = item.studentSceneDatasResponses.find(chi => chi.type === 2);
                        return {
                            ...item,
                            amMsg,
                            pmMsg
                        }
                    });
                    if (isFresh) {
                        lastReloadTime.value = new Date();
                        Toast('刷新成功');
                    }
                }
            });
        };
        fetchClassLog();
        const menuClick = (link) => {
            if (link === 'disable') {
                Toast('正在建设中');
                return;
            }
            router.push({
                path: link,
            });
        };
        const formatTime = () => {
            return dayjs(lastReloadTime.value).format('YYYY-MM-DD HH:mm:ss')
        }
        return {
            bannerList,
            menuList,
            agentList,
            agentListHasLoad,
            lastReloadTime,
            classList,
            classListHasLoad,
            currentClass,
            readApproveMore,
            readNoticeDetail,
            menuClick,
            fetchClassLog,
            formatTime,
            userName,
            hourName
        };
    },
};
</script>
<style lang="less" scoped>
.home-page {
    .swiper-img {
        width: 100%;
        height: 123px;
        /deep/ .van-image__img {
            border-radius: 10px;
        }
    }
    .notice {
        height: 33px;
        margin: 10px 0;
        padding: 0 10px;
        background: #fff;
        font-size: 14px;
        font-family: PingFang SC, serif;
        color: #666666;
        .left-icon {
            width: 12px;
            margin-right: 10px;
        }
        .right-icon {
            width: 13px;
        }
    }
    .menu-list {
        display: flex;
        height: 100px;
        background: #ffffff;
        border-radius: 10px;
        .menu-item {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        .title {
            font-size: 14px;
            font-family: PingFang SC, serif;
            font-weight: 400;
            line-height: 20px;
            color: #666666;
            margin-top: 6px;
        }
        img {
            width: 44px;
        }
    }
    .empty-wrapper {
        height: 200px;
    }

    .trajectory {
        margin-top: 10px;
    }
    .agent-item {
        padding: 10px 10px 10px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #f0f0f0;
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 6px;
            .name {
                font-size: 14px;
                font-family: PingFang SC, serif;
                font-weight: bold;
                line-height: 20px;
                color: #333333;
            }
            .time {
                font-size: 12px;
                font-family: PingFang SC, serif;
                font-weight: 400;
                line-height: 17px;
                color: #666666;
            }
        }
        .content {
            padding-right: 100px;
            box-sizing: border-box;
            font-size: 12px;
            font-family: PingFang SC, serif;
            line-height: 17px;
            color: #666666;
        }
    }
    .student-wrapper {
        .reload {
            display: flex;
            align-items: center;
            .time {
                font-size: 13px;
                font-family: PingFang SC, serif;
                color: #666666;
                margin-right: 8px;
            }
            img {
                width: 17px;
            }
        }
        /deep/ .common-content {
            margin-top: 12px;
        }
    }
    .total-wrapper {
        .am-item {
            padding-bottom: 15px;
            border-bottom: 1px solid #f0f0f0;
            margin-bottom: 15px;
        }
        .pm-item {
            padding-bottom: 58px;
        }
        .total-item {
            display: flex;
            align-items: flex-start;
            .label {
                font-size: 13px;
                font-family: PingFang SC, serif;
                font-weight: bold;
                line-height: 19px;
                color: #333333;
                margin: 0 26px 0 11px;
                flex-shrink: 0;
            }
            .detail {
                flex: 1;
                display: flex;
                justify-content: space-around;
                .item {
                    text-align: center;
                }
            }
            .des {
                font-size: 13px;
                font-family: PingFang SC, serif;
                line-height: 19px;
                color: #666666;
            }
            .count {
                font-size: 15px;
                font-family: PingFang SC, serif;
                font-weight: bold;
                line-height: 21px;
                color: #333333;
                margin-top: 6px;
            }
        }
    }
}
</style>
